function redirect(page) {
    var redirect = "";
     if(typeof page == "undefined" || page == null) {
        if(productTypeID == 1) { redirect = 'sommardack'; }
        if(productTypeID == 2) { redirect = 'friktionsdack'; }
        if(productTypeID == 3) { redirect = 'dubbdack'; }
    } else {
        if(productTypeID == 1) { redirect = 'sommardack?page=' + page;}
        if(productTypeID == 2) { redirect = 'friktionsdack?page=' + page;}
        if(productTypeID == 3) { redirect = 'dubbdack?page=' + page;}
    }

    // if(typeof page == "undefined" || page == null) {
    //     redirect = '/sommardack';
    // } else {
    //     redirect = '/sommardack?page=' + page;
    // }

    if (history.pushState) {
      window.history.pushState("", "", redirect);
    } else {
      document.location.href = redirect;
    }
    return;
}


$(document).on('click', '.pagination a', function(e) {
    e.preventDefault();
    var url = $(this).attr('href');
    var page = url.urlParam('page');
    // console.log(url, page);
    //redirect(page);

    $.ajax({
        type: 'GET',
        url: url, 
        data: {
            'page' : page
        },
        dataType: 'json',
        xhrFields: {
            withCredentials: true
        },
        success: function(data) {
            $('#searchResult').remove();
            $('.categoryFooter').remove();
            $('#searchContainer div.col-sm-12').append(data.searchResult);

            $('html, body').animate({scrollTop : $("#searchContainer").offset().top},700);
           
        }
    });
});


$(document).on('keyup keypress', 'form', function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) { 
    e.preventDefault();
    return false;
    }
});

$(document).on('change', '#tireType', function() {
    // console.log($('#tireType').val());
    $('#car-search-box form').attr('action', 'sok/reg/' + $('#tireType').val());
});

$(document).on('click', '#productType button', function() {
    $('#ddTireType').show();
    $('#productType .glyphicon').css("visibility","hidden");
    $(this).find('.glyphicon').css("visibility","visible");
    // console.log($(this).data('type'));

    if($(this).data('type') == 'sommardack') {
        $('#tireType').on('change', function() {
            // console.log($('#tireType').val());
            $('#car-search-box form').attr('action', 'sok/reg/' + $('#tireType').val());
        });
    } else {
        $('#car-search-box form').attr('action', 'sok/reg/' + $(this).data('type'));
    }
    
    // console.log($('#car-search-box form').attr('action'));

});

$(document).on('click', '#selectRims', function() {
    $('#ddTireType').hide();
});

var SearchActive = 0;
$(document).on('keyup', '#regNrSearch', function() {
    console.log("changing");
    if($(this).val().length == 6 && SearchActive == 0) {
        console.log("RUNS6");
        SearchActive = 1;
        
        var url = 'searchRegNr';
        var callOnSuccess = showRegnr;
        var args = { 
            'regnr' : $(this).val(),
            'productTypeID' : productTypeID
        };

        setTimeout(function(){ }, 1000);

        ajaxRequest(url, callOnSuccess, args);
    }
});

    function test(data)
    {
        console.log(data);

    }


$(document).on('keyup', '#search-manuf-text', function(){
    console.log('searching...');
    var valThis = $(this).val().toLowerCase();
    $('#carManufactorers button').each(function(){
        var text = $(this).data('search-item').toLowerCase();
        (text.indexOf(valThis) == 0) ? $(this).show() : $(this).hide();            
    });
});

$(document).on('keyup', '#search-model-text', function(){
    console.log('searching...');
    var valThis = $(this).val().toLowerCase();
    $('#carModels button').each(function(){
        var text = $(this).text().toLowerCase();
        (text.indexOf(valThis) == 0) ? $(this).show() : $(this).hide();            
    });
});

// $(document).on('keyup', '#search-manuf-text', function(){
//     var url = 'getCarManuf';
//     var callOnSuccess = listCarManufModels;
//     var args = { 'stext' : $('#search-manuf-text').val() };

//     ajaxRequest(url, callOnSuccess, args);
// }); 

// $(document).on('keyup', '#search-model-text', function(){
//     var url = 'getCarModels';
//     var callOnSuccess = listCarManufModels;
//     var args = { 
//         'stext' : $('#search-model-text').val(),
//         'carBrand' : $('#search-model-text').data('carbrand')
//     };

//     ajaxRequest(url, callOnSuccess, args);
// });

$(document).on('click', '#search-tabs a[href="#search-model-mobile"]', function() {
    $('#search-model-mobile .alert').remove();
    var url = 'getCarManuf';
    var callOnSuccess = listSearchCars;

    ajaxRequest(url, callOnSuccess);
});

$(document).on('click', '#carManufactorers button', function(){
    $('#search-model-mobile .alert').remove();
    var url = 'getCarModels';
    var callOnSuccess = listSearchCars;
    var args = { 
        'carBrand' : $(this).val()
    };

    ajaxRequest(url, callOnSuccess, args);
});

$(document).on('click', '#carModels button', function(){
    var url = 'searchRegNr';
    var callOnSuccess = showRegnr;
    var args = { 
        'modelid'       : $(this).data('modelid'),
        'ktype'         : $(this).data('ktype'),
        'productTypeID' : productTypeID
    };

    setTimeout(function(){ }, 1000);

    ajaxRequest(url, callOnSuccess, args);
});



function showLoading()
{

    $('#product_width').attr("disabled", true); 
    $('#product_width').css("background-color", "#f4f4f4");
    $('#product_width').parent().css( "background-color", "#f4f4f4" );   

    $('#product_profile').attr("disabled", true); 
    $('#product_profile').css("background-color", "#f4f4f4");
    $('#product_profile').parent().css( "background-color", "#f4f4f4" );   

    $('#product_inch').attr("disabled", true); 
    $('#product_inch').css("background-color", "#f4f4f4");
    $('#product_inch').parent().css( "background-color", "#f4f4f4" );   

    $('#product_brand').attr("disabled", true); 
    $('#product_brand').css("background-color", "#f4f4f4");
    $('#product_brand').parent().css( "background-color", "#f4f4f4" );   

    $('#product_model').attr("disabled", true); 
    $('#product_model').css("background-color", "#f4f4f4");
    $('#product_model').parent().css( "background-color", "#f4f4f4" );   

    $('.check-btn [name="runflat"]').iCheck('disable');
    $('.check-btn [name="cdack"]').iCheck('disable');
}


function hideLoading()
{
    $('#product_width').attr("disabled", false); 
    $('#product_width').css("background-color", "#fff");
    $('#product_width').parent().css( "background-color", "#fff" );   

    $('#product_profile').attr("disabled", false); 
    $('#product_profile').css("background-color", "#fff");
    $('#product_profile').parent().css( "background-color", "#fff" );   

    $('#product_inch').attr("disabled", false); 
    $('#product_inch').css("background-color", "#fff");
    $('#product_inch').parent().css( "background-color", "#fff" );   

    $('#product_brand').attr("disabled", false); 
    $('#product_brand').css("background-color", "#fff");
    $('#product_brand').parent().css( "background-color", "#fff" );   

    $('#product_model').attr("disabled", false); 
    $('#product_model').css("background-color", "#fff");
    $('#product_model').parent().css( "background-color", "#fff" );  

}


$(document).on('change', '#product_width', function(){

    showLoading();

    var url = 'filterByWidth/' + productTypeID;
    var callOnSuccess = showDimensionsByWidth;
    var args = { 
        'product_width'     : $(this).val(),
        'is_ctyre'          : $('.check-btn [name="cdack"]').is(':checked') ? 1:0,
        'is_runflat'        : $('.check-btn [name="runflat"]').is(':checked') ? 1:0,
        'exclude_products'  : true,
        'filter_this'       : 'product_width'
    };

    ajaxRequest(url, callOnSuccess, args);
});

$(document).on('change', '#product_profile', function(){

    showLoading();

    var url = 'filterByProfile/' + productTypeID;
    var callOnSuccess = showDimensionsByProfile;
    //var callOnSuccess = testings;

    var args = { 
        'product_width'     : $('#product_width').val(),
        'product_profile'   : $(this).val(),
        'is_ctyre'          : $('.check-btn [name="cdack"]').is(':checked') ? 1:0,
        'is_runflat'        : $('.check-btn [name="runflat"]').is(':checked') ? 1:0,
        'exclude_products'  : true,
        'filter_this'       : 'product_profile'     
    };

    ajaxRequest(url, callOnSuccess, args);
});

$(document).on('change', '#product_inch', function(){

    showLoading();

    var url = 'filterByInch/' + productTypeID;
    var callOnSuccess = showDimensionsByInch;
    var args = { 
        'product_width'     : $('#product_width').val(),
        'product_profile'   : $('#product_profile').val(),
        'product_inch'      : $(this).val(),
        'is_ctyre'          : $('.check-btn [name="cdack"]').is(':checked') ? 1:0,
        'is_runflat'        : $('.check-btn [name="runflat"]').is(':checked') ? 1:0,
        'exclude_products'  : true,
        'filter_this'       : 'product_inch'
    };

    ajaxRequest(url, callOnSuccess, args);
});

$(document).on('change', '#product_brand', function(){

    showLoading();

    var url = 'filterByBrand/' + productTypeID;
    var callOnSuccess = showDimensionsByBrand;
    var args = { 
        'product_width'     : $('#product_width').val(),
        'product_profile'   : $('#product_profile').val(),
        'product_inch'      : $('#product_inch').val(),
        'product_brand'     : $(this).val(),
        'is_ctyre'          : $('.check-btn [name="cdack"]').is(':checked') ? 1:0,
        'is_runflat'        : $('.check-btn [name="runflat"]').is(':checked') ? 1:0,
        'exclude_products'  : true,
        'filter_this'       : 'product_brand'
    };

    ajaxRequest(url, callOnSuccess, args);
});
 

$(document).on('change', '#product_model', function(){

    showLoading();

    var url = 'filterByModel/' + productTypeID;
    var callOnSuccess = showDimensionsByModel;
    var args = { 
        'product_width'     : $('#product_width').val(),
        'product_profile'   : $('#product_profile').val(),
        'product_inch'      : $('#product_inch').val(),
        'product_brand'     : $('#product_brand').val(),
        'product_model'     : $(this).val(),
        'is_ctyre'          : $('.check-btn [name="cdack"]').is(':checked') ? 1:0,
        'is_runflat'        : $('.check-btn [name="runflat"]').is(':checked') ? 1:0,
        'exclude_products'  : true,
        'filter_this'       : 'product_model'
    };

    ajaxRequest(url, callOnSuccess, args);
});

$(document).on('ifToggled', '.check-btn', function(){
    var url = 'filterByModel/' + productTypeID;
    var callOnSuccess = showDimensionsByModel;
    var args = { 
        'product_width'     : $('#product_width').val(),
        'product_profile'   : $('#product_profile').val(),
        'product_inch'      : $('#product_inch').val(),
        'product_brand'     : $('#product_brand').val(),
        'product_model'     : $('#product_model').val(),
        'is_ctyre'          : $('.check-btn [name="cdack"]').is(':checked') ? 1:0,
        'is_runflat'        : $('.check-btn [name="runflat"]').is(':checked') ? 1:0,
        'exclude_products'  : true,
        'filter_this'       : 'product_model'
    };

    ajaxRequest(url, callOnSuccess, args);
});




$(document).on('click', '#empty_filter', function(){

    showLoading();

    var url = 'reloadFilterTires/' + productTypeID;
    console.log("EMPTY FILTER");

    var callOnSuccess = showReloadFilterTires;
    var args = { 
        'emptOut' : true
    };

    ajaxRequest(url, callOnSuccess, args);
});



$(document).on('change', '#size', function(){
   
    var url = 'setTireSize';
    
    var newSize = $('#size').val();

    var callOnSuccess = '';
    var args = { 
        'size'     : newSize
    };
 
    ajaxRequest(url, callOnSuccess, args);
    
});



function reloadFilterTires(productTypeID)
{
    showLoading();
    
    var url = 'reloadFilterTires/' + productTypeID;
    console.log('RELOADING FILTERS ' + productTypeID);

    var callOnSuccess = showReloadFilterTires;
    ajaxRequest(url, callOnSuccess);
}


function ajaxRequest(url, callOnSuccess, args) {
    if (args === undefined) {
        args = null;
    }
    $.ajax({
        type: 'get',
        url: url,
        data: args,
        dataType: 'json',
        xhrFields: {
            withCredentials: true
        },
        success: callOnSuccess
    });
}

var showRegnr = function (data) {
    // $('#car-search-box form').empty();
    // $('#car-search-box form').append(data.searchResult);

    // $('#selectTires .glyphicon').css("visibility","visible");
    // $('#search-tabs a[href="#search-reg-mobile"]').tab('show');
    
    $('#search-model-mobile .alert').remove();
    if( data.searchResult !== undefined ) {
        $('#car-search-box form').empty();
        $('#car-search-box form').append(data.searchResult);

        $('#selectTires .glyphicon').css("visibility","visible");

        $('#search-tabs a[href="#search-reg-mobile"]').tab('show');

        var productTypeURL = '';
        var tireType = '';

        if(productTypeID == 1) { productTypeURL = 'sok/reg/sommardack'; tireType = 'sommardack'; } 
        if(productTypeID == 2) { productTypeURL = 'sok/reg/friktionsdack'; tireType = 'friktionsdack'; } 
        if(productTypeID == 3) { productTypeURL = 'sok/reg/dubbdack'; tireType = 'dubbdack'; }

        $('#car-search-box form').attr('action', productTypeURL);
        $('#tireType').val(tireType);


    } else if(data.error) {
        SearchActive = 0;
        $('#search-tabs li:eq(0)').removeClass('active');
        $('#search-tabs li:eq(1)').addClass('active');
        $('#search-reg-mobile').removeClass('active');
        $('#search-model-mobile').addClass('active');
        $('#search-model-mobile').prepend(
                '<div class="alert alert-danger" style="margin-left:0px;">' +
                    '<p>' + data.error + '</p>' +
                '</div>'
        );
           
        var productTypeURL = '';
        if(productTypeID == 1) { productTypeURL = 'sok/reg/sommardack'; } 
        if(productTypeID == 2) { productTypeURL = 'sok/reg/friktionsdack'; } 
        if(productTypeID == 3) { productTypeURL = 'sok/reg/dubbdack'; } 
        $('#car-search-box form').attr('action', productTypeURL);

        var url = 'getCarManuf';
        var callOnSuccess = listSearchCars;

        ajaxRequest(url, callOnSuccess);
    }
};

// var listCarManufModels = function(data) {
//     $('#search-model-mobile .list-group').remove();
//     $('#search-model-mobile').append(data); 

//     $('input').iCheck({
//         // checkboxClass: 'icheckbox_minimal-green',
//         // radioClass: 'iradio_minimal-green'

//         checkboxClass: 'icheckbox_square-green iCheck-margin',
//         radioClass: 'iradio_square-grey iChk iCheck-margin'
//     });
// };

var listSearchCars = function(data) {

    //console.log('listing search cars');
    //console.log(data);
    $('#search-model-mobile .list-group').remove();
    $('#search-model-mobile .inner-addon').remove();
    $('#search-model-mobile').append(data); 
};

var showReloadFilterTires= function(data) {

    $('#product_width').html(data.html.dropdownWidth);    
    $('#product_profile').html(data.html.dropdownProfile);
    $('#product_inch').html(data.html.dropdownInch);
    $('#product_brand').html(data.html.dropdownBrand);
    $('#product_model').html(data.html.dropdownModel);
 
    if(data.countRunFlat < 1)
    {
        $('.check-btn [name="runflat"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="runflat"]').iCheck('enable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');

        if(data.setRunFlat > 0)
        {
            $('.check-btn [name="runflat"]').iCheck('check');
        }
        else
        {
            $('.check-btn [name="runflat"]').iCheck('uncheck');          
        }
    }
    if(data.countCTyre < 1)
    {
        $('.check-btn [name="cdack"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="cdack"]').iCheck('enable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');

        if(data.setCTyre > 0)
        {
            $('.check-btn [name="cdack"]').iCheck('check');
        }
        else
        {
            $('.check-btn [name="cdack"]').iCheck('uncheck');
        }
    }   
    hideLoading(); 
};


var showDimensionsByWidth = function(data) {
    $('#product_profile').html(data.html.dropdownProfile);
    $('#product_inch').html(data.html.dropdownInch);
    $('#product_brand').html(data.html.dropdownBrand);
    $('#product_model').html(data.html.dropdownModel);
    if(data.countRunFlat < 1)
    {
        $('.check-btn [name="runflat"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="runflat"]').iCheck('enable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');
    }
    if(data.countCTyre < 1)
    {
        $('.check-btn [name="cdack"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="cdack"]').iCheck('enable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');
    }    

    hideLoading();
};

var showDimensionsByProfile = function(data) {
    $('#product_inch').html(data.html.dropdownInch);
    $('#product_brand').html(data.html.dropdownBrand);
    $('#product_model').html(data.html.dropdownModel);
    if(data.countRunFlat < 1)
    {
        $('.check-btn [name="runflat"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="runflat"]').iCheck('enable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');
    }
    if(data.countCTyre < 1)
    {
        $('.check-btn [name="cdack"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="cdack"]').iCheck('enable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');
    }  

    hideLoading(); 
};

var showDimensionsByInch = function(data) {
    $('#product_brand').html(data.html.dropdownBrand);
    $('#product_model').html(data.html.dropdownModel);
    if(data.countRunFlat < 1)
    {
        $('.check-btn [name="runflat"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="runflat"]').iCheck('enable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');
    }
    if(data.countCTyre < 1)
    {
        $('.check-btn [name="cdack"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="cdack"]').iCheck('enable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');
    }    

    hideLoading();
};

var showDimensionsByBrand = function(data) {
    $('#product_model').html(data.html.dropdownModel);
    if(data.countRunFlat < 1)
    {
        $('.check-btn [name="runflat"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="runflat"]').iCheck('enable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');
    }
    if(data.countCTyre < 1)
    {
        $('.check-btn [name="cdack"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="cdack"]').iCheck('enable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');
    }

    hideLoading();    
};

var showDimensionsByModel = function(data) {
    
    if(data.countRunFlat < 1)
    {
        $('.check-btn [name="runflat"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="runflat"]').iCheck('enable');
        $('.check-btn [name="runflatlabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');
    }
    if(data.countCTyre < 1)
    {
        $('.check-btn [name="cdack"]').iCheck('uncheck').iCheck('disable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'italic').css('color', 'gray').css('cursor', 'default');   
    }
    else
    {
        $('.check-btn [name="cdack"]').iCheck('enable');
        $('.check-btn [name="cdacklabel"]').css('font-style', 'initial').css('color', 'initial').css('cursor', 'pointer');
    }

    hideLoading();
};


$(document).ready(function() {
    $('#loading-image').hide();
});

$(document).ajaxStart(function(){
    $('#loading-image').show();
}).ajaxStop(function(){
    $('#loading-image').hide();
});

////////////////////////////
// Twitter: @mikedevelops
////////////////////////////

// your custome placeholder goes here!
var ph = "ABC123",
    searchBar = $('#regNrSearch'),
    // placeholder loop counter
    phCount = 0;

// function to return random number between
// with min/max range
function randDelay(min, max) {
    return Math.floor(Math.random() * (max-min+1)+min);
}

// function to print placeholder text in a 
// 'typing' effect
function printLetter(string, el) {
    // split string into character seperated array
    var arr = string.split(''),
        input = el,
        // store full placeholder
        origString = string,
        // get current placeholder value
        curPlace = $(input).attr("placeholder"),
        // append next letter to current placeholder
        placeholder = curPlace + arr[phCount];
        
    setTimeout(function(){
        // print placeholder text
        $(input).attr("placeholder", placeholder);
        // increase loop count
        phCount++;
        // run loop until placeholder is fully printed
        if (phCount < arr.length) {
            printLetter(origString, input);
        }
    // use random speed to simulate
    // 'human' typing
    }, randDelay(200, 300));
}  

// function to init animation
function placeholder() {
    $(searchBar).attr("placeholder", "");
    printLetter(ph, searchBar);
}

window.setTimeout(placeholder, 1500);
$('.submit').click(function(e){
    phCount = 0;
    e.preventDefault();
    placeholder();
});